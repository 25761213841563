import React, { useEffect } from "react";
import WOW from 'wow.js';

import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaUsers,
} from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";

const Footer = () => {

  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  return (
    <div>
      <section class="z-[100] bg-white">
        <div class="footer ">
          <main class="max-w-screen-2xl m-auto pt-10 pb-6 px-5 md:px-10 lg:px-24">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-3 border-b-[0.5px] border-b-[#dedede3a] pb-2 wow slideInUp" data-wow-duration="1s">
              <div class="flex justify-center lg:justify-start">
                <figure>
                  <img alt="logo" class="w-20" src="/FixlabsLogo.png" />
                </figure>
              </div>
              <div class="flex flex-col lg:text-start text-center">
                <p class="text-lg font-semibold md:font-bold font-CircularRegular text-[#ea580d] mb-1">
                  Registered office
                </p>
                {/* <span className=" text-[#ea580d]">Address:</span> */}
                <span className="text-xs md:text-sm">
                  WeWork Eldeco Centre, Block A, Shivalik Colony,
                  <br /> Malviya Nagar, New Delhi, 110017
                </span>

                {/* <span className=" text-[#ea580d]">Email:</span> */}
                <span className="text-sm my-2 gap-2 flex items-center lg:justify-start justify-center">
                  <TfiEmail />
                  info@fixlabs.ai
                </span>
              </div>
              <div class="flex md:items-start flex-col lg:items-end w-50 ">
                <p class="text-lg font-semibold md:font-bold font-CircularRegular lg:text-left text-center text-[#ea580d] mb-4">
                  Follow us on
                </p>
                <div class="flex gap-3 lg:justify-start justify-center">
                  <a className="p-2 bg-orange-600 text-white rounded-full"
                    href="https://twitter.com/DhanHQ"
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    <FaFacebook className="transform transition-transform duration-500 hover:rotate-[360deg]" />
                  </a>
                  <a className="p-2 bg-orange-600 text-white rounded-full"
                    href="https://t.me/JoinDhan"
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    <FaInstagram className="transform transition-transform duration-500 hover:rotate-[360deg]" />
                  </a>
                  <a className="p-2 bg-orange-600 text-white rounded-full"
                    href="https://www.linkedin.com/company/dhanhq/?viewAsMember=true"
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    <FaTwitter className="transform transition-transform duration-500 hover:rotate-[360deg]" />
                  </a>
                  <a className="p-2 bg-orange-600 text-white rounded-full"
                    href="https://www.youtube.com/channel/UCEzHCpvFWoF85UabbzKTkOQ"
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    <FaLinkedin className="transform transition-transform duration-500 hover:rotate-[360deg]" />
                  </a>
                </div>
              </div>
              {/* <div class="border-b border-b-[#dedede3a] lg:border-b-0 pb-10"> */}
              {/* <div class="pt-10 flex items-center flex-col lg:items-start">
                  <p class="text-lg font-semibold font-CircularRegular text-[#ea580d] mb-4">
                    Join us on
                  </p>
                  <a
                    href="https://madefortrade.in/"
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    <FaUsers />
                  </a>
                </div> */}
              {/* <div class="pt-1 flex items-center flex-col lg:items-center w-50">
                  <p class="text-lg font-bold font-CircularRegular text-[#ea580d] mb-4">
                    Follow us on
                  </p>
                  <div class="flex gap-5">
                    <a
                      href="https://twitter.com/DhanHQ"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <FaFacebook className="text-lg" />
                    </a>
                    <a
                      href="https://t.me/JoinDhan"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <FaInstagram />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/dhanhq/?viewAsMember=true"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <FaTwitter />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCEzHCpvFWoF85UabbzKTkOQ"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </div> */}
              {/* </div> */}
              {/* <div class="lg:col-span-3">
                <div class="grid grid-cols-2 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 lg:gap-8 gap-6">
                  <div class="lg:flex lg:items-center lg:flex-col">
                    <div>
                      <p class="text-[#ea580d] font-bold text-base lg:text-lg font-CircularMedium">
                        Customer Service
                      </p>
                      <ul class="pt-5">
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a
                            href="https://knowledge.dhan.co/support/home"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Knowledge Base
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/customer-service/">Customer Support</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/safety-security/">Safety and Security</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/grievance/">Grievance Redressal</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/advisory/">Advisory for Investors</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/rights-entitlements/">RE Awareness</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/secc-regulations/">SECC Regulations</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="lg:flex md:-order-none order-[3] lg:items-center lg:flex-col">
                    <div>
                      <p class="text-[#ea580d] font-bold text-base lg:text-lg font-CircularMedium">
                        Our Products
                      </p>
                      <ul class="pt-5">
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/trading-app/">Dhan App</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/web-trading-platform/">Dhan Web</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/options-trader/">Options Trader App</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/options-trader-web/">Options Trader Web</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a
                            href="https://dhanhq.co/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            DhanHQ Trading APIs
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/tradingview/">TradingView on Dhan</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/connect-to-tradingview/">
                            Connect to TradingView
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/smallcase/">smallcases on Dhan</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/margin-trading-facility/">
                            Margin Trading Facility
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/margin-pledge-benefit/">
                            Margin Pledge Benefit
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/scanx-stock-screener/">
                            Scanx Stock Screener
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="lg:flex md:-order-none order-[2] lg:items-center lg:flex-col">
                    <div>
                      <p class="text-[#ea580d] font-bold text-base lg:text-lg font-CircularMedium">
                        Quick Links
                      </p>
                      <ul class="pt-5">
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/open-demat-account/">Open Demat Account</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/open-huf-demat-account/">
                            HUF Demat Account
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/open-corporate-demat-account/">
                            Corporate Demat Account
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/download-centre/">Download Centre</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/calculators/">Calculators</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/finance-glossary/">Finance Glossary</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/market-holiday/">Stock Market Holidays</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/muhurat-trading/">Muhurat Trading 2025</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="https://www.upsurge.club/" target="_blank">
                            Stock Market Courses
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/meet-and-greet/">Meet &amp; Greet</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a
                            href="https://status-monitor.dhan.co/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Dhan Connectivity Status
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/regulatory-information/">
                            Regulatory Information
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="lg:flex md:-order-none order-[4] lg:items-center lg:flex-col">
                    <div>
                      <p class="text-[#ea580d] font-bold text-base lg:text-lg font-CircularMedium">
                        Partner with us
                      </p>
                      <ul class="pt-5">
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/authorised-partner/">Authorised Person</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a
                            href="https://partner.dhan.co/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Partner Dashboard
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/code-of-conduct-for-authorised-partners/">
                            Code of Conduct
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="lg:flex lg:items-center lg:flex-col">
                    <div>
                      <p class="text-[#ea580d] font-bold text-base lg:text-lg font-CircularMedium">
                        Company
                      </p>
                      <ul class="pt-5">
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/about/">About Us</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a
                            href="https://blog.dhan.co/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Dhan Blog
                          </a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/pricing/">Pricing</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/career/">Careers</a>
                        </li>
                        <li class="text-sm text-[#8E8E8E] mb-3 w-max font-CircularRegular hover:text-[#dedede] cursor-pointer">
                          <a href="/contact/">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </main>
          {/* <main class="pb-10">
            <div class="max-w-[1550px] m-auto px-5 md:px-10 lg:px-24">
              <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-8">
                <div class="flex items-center gap-2">
                  <figure>
                    <div class="w-9" title="Heart"></div>
                  </figure>
                  <div>
                    <p class="text-sm font-CircularRegular text-[#8E8E8E]">
                      built with love
                      <br />
                      <span class="text-[#DADADA]">
                        for Indians who love to invest &amp; trade
                      </span>
                    </p>
                  </div>
                </div>
                <div class="flex items-center lg:justify-end gap-3">
                  <figure>
                    <a
                      href="https://raiseholding.co"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="raise"
                        src="/_next/static/media/raise.8e420eb9.svg"
                      />
                    </a>
                  </figure>
                  <div>
                    <p class="text-sm font-CircularRegular text-[#8E8E8E]">
                      We are part of Raise Financial Services
                    </p>
                  </div>
                </div>
                <div class="flex justify-start lg:justify-end gap-3">
                  <figure>
                    <a
                      href="https://apps.apple.com/in/app/dhan/id1575318726"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="raise"
                        id="app-store-button-footer-common"
                        src="/_next/static/media/Appstore.41904f07.svg"
                      />
                    </a>
                  </figure>
                  <figure>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.dhan.live"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="raise"
                        id="play-store-button-footer-common"
                        src="/_next/static/media/Playstore.c6791547.svg"
                      />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
          </main> */}
        </div>
      </section>
      <div class="bg-orange-600 py-3 text-xs md:text-sm text-center text-white">
        <p>&copy; 2024 Fix Labs Pvt Ltd. All rights reserved.</p>
      </div>
    </div>
  );
};
export default Footer;
