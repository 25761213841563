import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';

const Experience = () => {
    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.5,
            }
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <div className="bg-gray-100 pb-10 lg:pb-20 pt-20">
            <h1 className="text-black lg:text-3xl text-lg md:text-2xl font-CircularMedium text-center mb-10 md:mb-10 pb-5 wow fadeInDown">
                Strong Performance and Clients{' '}
                <span className="text-[#ea580d]">Engagement to Drive Growth</span>
            </h1>
            <div
                className="mx-auto max-w-7xl px-6 lg:px-8"
                ref={counterRef}
            >
                <dl className="grid grid-cols-1 gap-x-8 gap-y-8 md:gap-y-16 text-center lg:grid-cols-3">
                    <div className="mx-auto flex max-w-xs flex-col gap-y-2">
                        <dt className="text-base/7 text-gray-900">Brands using our solutions</dt>
                        <dd className="order-first text-2xl md:text-3xl font-semibold md:font-bold tracking-tight text-orange-600 sm:text-5xl">
                            {isVisible && <CountUp end={100} suffix="+" duration={2} />}
                        </dd>
                    </div>
                    <div className="mx-auto flex max-w-xs flex-col gap-y-2">
                        <dt className="text-base/7 text-gray-900">Transactions on Platform</dt>
                        <dd className="order-first text-2xl md:text-3xl font-semibold md:font-bold tracking-tight text-orange-600 sm:text-5xl">
                            {isVisible && <CountUp end={10000} suffix="+" duration={2.5} />}
                        </dd>
                    </div>
                    <div className="mx-auto flex max-w-xs flex-col gap-y-2">
                        <dt className="text-base/7 text-gray-900">New users monthly</dt>
                        <dd className="order-first text-2xl md:text-3xl font-semibold md:font-bold tracking-tight text-orange-600 sm:text-5xl">
                            {isVisible && <CountUp end={25000} suffix="+" duration={3} />}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
};

export default Experience;
