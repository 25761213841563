import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import WOW from 'wow.js';

const HeroVideoSection = () => {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  return (
    <>
      <div className="relative  md:h-min-screen overflow-hidden">
        {/* Video Background */}
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src="/hero2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Content Overlay */}
        <div className="relative flex items-center justify-center h-full bg-black bg-opacity-50" data-wow-duration="5s">
          <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center justify-between px-6 py-20">
            <div className="w-full max-w-4xl mx-auto text-center min-h-96 my-10 py-10 wow zoomIn">
              <h1 className="text-xl md:text-2xl lg:text-4xl font-semibold lg:font-bold text-white leading-tight capitalize">
                We are Bharat’s first full-stack unified
              </h1>
              <h1 className="text-xl md:text-3xl mt-2 lg:text-4xl font-semibold lg:font-bold text-white leading-tight capitalize">
                SaaS platform solutions</h1>
              <p className="mt-7 text-gray-300 text-sm md:text-base lg:text-lg">
                Our innovative approach empowers businesses with end-to-end tools to seamlessly</p>
              <p className=" text-gray-300 text-sm md:text-base lg:text-lg">scale, streamline operations, and drive growth in the digital era.
              </p>
              <div className="mt-8 flex justify-center items-center text-xs md:text-base">
                <Link target="_blank" to="https://fixall.ai" className=" px-6 py-3 bg-orange-600 text-white font-medium rounded shadow hover:bg-orange-700">
                  Access Your Apps
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroVideoSection;