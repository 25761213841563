import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wow.js';
import XpressbaazaarPhone from '../Images/xpressBaazaarPhone.png';

const Xpressbaazaar = () => {

  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  return (
    <div>
      <div class="bg-no-repeat bg-cover w-full lg:bg-[url('https://stock-logos.dhan.co/static-openweb/line-banner.png')] md:bg-[url('https://stock-logos.dhan.co/static-openweb/line-banner.png')] bg-[url('https://stock-logos.dhan.co/static-openweb/lines-mobile.png')]">
        <div class="max-w-[1550px] m-auto lg:pt-20 pt-10 lg:px-24 h1ss md:px-10 px-4">
          <div class="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-20 gap-6 wow zoomIn" data-wow-duration="2s">
            <div class="lg:pb-20 md:pb-10 pb-5 flex justify-center flex-col col-span-2">
              <div className='flex justify-center md:justify-start'>
              <img alt="img1" class="h-10 md:h-20 " src="/xpressbaazaarLogo.png" />
              </div>
              <p class="text-[#8E8E8E] lg:pt-5 text-sm md:text-xs lg:text-base font-CircularRegular pt-4 lg:text-left md:text-left text-center">
               Xpressbaazaar is Open Network Digital Commerce, who will enable buyers and sellers to be digitally visible by adopting the Xpressbaazaar architecture.       </p>
              <br />
              <div class="grid lg:grid-cols-2 grid-cols-1 gap-4">
                <div class="lg:col-span-2 ">
                  <div class="grid gridcols-1 md:grid-cols-2 grid-rows-2 md:gap-8 gap-5">
                    <div class="flex justify-center md:justify-left items-center justify-between gap-3">
                      <a class="font-CircularBook lg:text-base text-[13px] text-[#DADADA] lg:hover:text-[#EF9309] flex items-center gap-1" href="/mutual-funds/equity-funds/">
                        <img alt="dsds" class="h-8" src="/digitalbusinessVector.png" />
                        <p class="text-[#DADADA] pl-2 hover:text-[#EF9309] lg:text-xl md:text-base text-xs font-CircularMedium flex gap-1 items-center">
                          Digital Visibility for Businesses
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right w-2.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg>
                        </p>
                      </a>
                    </div>
                    <div class="flex justify-center md:justify-left items-center justify-between gap-3">
                      <a class="font-CircularBook lg:text-base text-[13px] text-[#DADADA] lg:hover:text-[#EF9309] flex items-center gap-1" href="/mutual-funds/debt-funds/">
                        <img alt="dsds" class="h-8" src="https://dhan.co/_next/static/media/Debt_Funds.5e1ef92e.svg" />
                        <p class="text-[#DADADA] pl-2 hover:text-[#EF9309] lg:text-xl md:text-base text-xs font-CircularMedium flex gap-1 items-center">
                          Seamless Onboarding Process
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right w-2.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg>
                        </p>
                      </a>
                    </div>
                    <div class="flex justify-center md:justify-left items-center justify-between gap-3">
                      <a class="font-CircularBook lg:text-base text-[13px] text-[#DADADA] lg:hover:text-[#EF9309] flex items-center gap-1" href="/mutual-funds/hybrid-funds/">
                        <img alt="dsds" class="h-8" src="https://dhan.co/_next/static/media/Hybrid_Funds.d9d75eed.svg" />
                        <p class="text-[#DADADA] pl-2 hover:text-[#EF9309] lg:text-xl md:text-base text-xs font-CircularMedium flex gap-1 items-center">
                          Integrated Payment Solutions
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right w-2.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg>
                        </p>
                      </a>
                    </div>
                    <div class="flex justify-center md:justify-left items-center justify-between gap-3">
                      <a class="font-CircularBook lg:text-base text-[13px] text-[#DADADA] lg:hover:text-[#EF9309] flex items-center gap-1" href="/mutual-funds/elss-funds/">
                        <img alt="dsds" class="h-8" src="/ordertrackingVector.jpg" />
                        <p class="text-[#DADADA] pl-2 hover:text-[#EF9309] lg:text-xl md:text-base text-xs font-CircularMedium flex gap-1 items-center">
                          Real-Time Order Tracking
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right w-2.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg>
                        </p>
                      </a>
                    </div>
                    <div class="flex justify-center md:justify-left items-center gap-3 justify-between">
                      <a class="font-CircularBook lg:text-base text-[13px] text-[#DADADA] lg:hover:text-[#EF9309] flex items-center gap-1" href="/mutual-funds/index-funds/">
                        <img alt="dsds" class="h-7" src="https://stock-logos.dhan.co/static-openweb/indexFunds.svg" />
                        <p class="text-[#DADADA] pl-2 hover:text-[#EF9309] lg:text-xl md:text-base text-xs font-CircularMedium flex gap-1 items-center">
                          Extensive Product Discovery
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right w-2.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg>
                        </p>
                      </a>
                    </div>
                  </div>
                  <div class="lg:justify-start md:justify-start lg:pt-14 pt-10 flex justify-center">
                    <Link to="https://xpressbaazaar.com/">
                      <button class="lg:px-10 px-7 btn btn-anim butnshadow lg:py-3 py-2 bg-[#ea580d] text-[#fff] flex lg:text-base text-sm rounded-[6px] btn1 items-center gap-2 font-CircularMedium mainbtn">
                        Explore Product
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right w-2.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden md:flex justify-end items-end">
              <img alt="trading view" class="lg:h-[80%]" src={XpressbaazaarPhone} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Xpressbaazaar