import React from "react";
import Layout from "../components/layout";
import HeroVideoSection from "../screens/heroVideoSection";
import Experience from "../screens/Experience";
import Solutions from "../screens/solutions";
import Xpressbaazaar from "../screens/xpressbaazaar";
import Inficonnect from "../screens/inficonnect";
import Qwikco from "../screens/qwikco";
import Zeroclick from "../screens/zeroclick";

const Home = () => {
  return (
    <Layout>
      <HeroVideoSection />
      <Solutions />
      <Inficonnect />
      <Xpressbaazaar />
      <Zeroclick />
      <Experience />
      <Qwikco />
    </Layout>
  );
};

export default Home;
