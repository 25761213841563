import React, { useState , useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

 useEffect(()=>{
    if(menuOpen){
      document.body.classList.add("overflow-hidden")
    }else{
      document.body.classList.remove("overflow-hidden")
    }
 },[menuOpen])

  return (
    <>
      <div className="bg-orange-600 text-white md:py-1 text-center">
        <span className="text-xs md:text-sm">Our Unified Solutions !</span>
      </div>
      <header className="bg-white w-full top-0 z-50 sticky">
        <nav className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link
            to="/"
            className="text-2xl font-bold flex items-center space-x-2 p-0"
          >
            <img
              className="h-10 w-auto"
              src="/Fixlabs.jpg"
              alt="Your Company"
            />
          </Link>
          <div className="hidden md:flex space-x-8 text-center">
            <Link to="/aboutus" className="hover:text-orange-600 ">
              About Us
            </Link>
            {/* <Link to="https://fixall.ai/" className="hover:text-orange-600">
              Business
            </Link> */}
            <a
              href="https://fixall.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-orange-600 "
            >
              Business
            </a>
            <Link to="/culture" className="hover:text-orange-600">
              Culture
            </Link>
            <Link to="/investors" className="hover:text-orange-600">
              Investors
            </Link>
            <Link to="/contact" className="hover:text-orange-600">
              Contact
            </Link>
          </div>
          {/* <Link
            target="_blank"
            className="hidden md:block bg-orange-600 text-white px-6 py-2 rounded"
          >
            Sign Up
          </Link> */}
          <div
            className="md:hidden text-2xl cursor-pointer"
            onClick={toggleMenu}
          >
            {menuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </nav>
        {/* Mobile Menu */}
        <div
          className={`${menuOpen ? "block h-screen " : "hidden"
            } md:hidden bg-white -md space-y-5 p-5 flex flex-col`}
        >
          <Link href="/aboutus" className="hover:text-orange-600">
            About Us
          </Link>
          {/* <Link href="https://fixall.ai/" className="hover:text-orange-600">
              Business
            </Link> */}
          <a
            href="https://fixall.ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-orange-600"
          >
            Business
          </a>
          <Link href="/culture" className="hover:text-orange-600">
            Culture
          </Link>
          <Link href="/investors" className="hover:text-orange-600">
            Investors
          </Link>
          <Link href="/contact" className="hover:text-orange-600">
            Contact
          </Link>
        </div>
      </header>
    </>
  );
};

export default Header;
