import React from 'react'
import Layout from '../components/layout'

function Aboutus() {
  return (
    <Layout>
      <div className="bg-gray-100 text-gray-800">
      {/* Container */}
      <div className="max-w-7xl mx-auto p-6 sm:p-10">
        {/* Title */}
        <h1 className="text-2xl md:text-4xl  sm:text-5xl font-semibold lg:font-bold text-teal-600 text-center mb-8">
          About Us
        </h1>

        {/* Content and Image Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Left Text Section */}
          <div className="flex-1">
            <h2 className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-700 mb-4">
              Empowering Businesses with Innovation
            </h2>
            <p className="text-sm md:text-base lg:text-lg leading-relaxed mb-4">
              At Fixlabs, we take pride in being Bharat’s first full-stack,
              unified SaaS platform solution. Our innovative approach empowers
              businesses with end-to-end tools to seamlessly scale, streamline
              operations, and drive growth in the digital era.
            </p>
            <p className="text-sm md:text-base lg:text-lg leading-relaxed mb-4">
              By integrating inventory, sales, marketing, and logistics, our
              platform ensures businesses can scale faster, personalize
              experiences, and boost revenue while reducing operational
              complexity.
            </p>
            <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-700 mt-6 mb-4">
              Why Choose Fixlabs?
            </h3>
            <ul className="list-disc list-inside space-y-2 text-sm md:text-base lg:text-lg">
              <li>End-to-End Business Automation</li>
              <li>Real-Time Insights with Advanced Analytics</li>
              <li>Unified Inventory and Sales Management</li>
              <li>Streamlined Logistics for Maximum Efficiency</li>
              <li>Tools to Scale, Innovate, and Thrive</li>
            </ul>
          </div>

          {/* Right Image Section */}
          <div className="flex-1">
            <img
              src="/aboutus.jpg"
              alt="Fixlabs Features"
              className="rounded-lg shadow-md w-full max-w-sm lg:max-w-md mx-auto"
            />
          </div>
        </div>
      </div>
    </div>

    </Layout>

  )
}

export default Aboutus