import React from 'react'
import { Link } from 'react-router-dom'
import laptopImage from "../Images/dashboardLaptop.png"

const Inficonnect = () => {
  return (
    <div>
      <section class="max-w-[1550px] m-auto lg:pb-10 lg:pt-10 bg-gray-100 pb-8 pt-10 lg:px-32 px-5">
        <div class="flex md:flex pb-5 justify-center">
          <div class="flex gap-2.5 items-center">
            <h2 class="text-[#363636] lg:text-3xl text-xl md:text-2xl font-CircularMedium text-center wow fadeInDown" data-wow-animation="1s">
              Building Features
              <span class="text-[#ea580d]"> Vendors Love The Most</span>
            </h2>
          </div>
          <br />
        </div>
        <div class="">
          <main class="grid lg:grid-cols-2 grid-cols-1 gap-5">
            <div class="row-span-2 flex justify-center wow slideInLeft" data-wow-duration="1s">
              <div class="block tab_img flex justify-left lg:justify-left items-center">
                <img alt="img1" class="w-[90%] md:w-[50%] lg:w-[90%]" src={laptopImage} />
              </div>
            </div>
            <div class="wow slideInRight" data-wow-duration="1s">
              <div class="block tabscontent mb-5">
                <div class="flex justify-center lg:justify-start items-center pt-4 gap-3">
                  <img alt="img1" class="h-10" src="/inficonnectLogo.png" />
                </div>
                <p class="text-[#817E7E] lg:text-left text-center lg:text-base text-sm pt-5 font-CircularBook">
                  A comprehensive platform for connecting vendors and brands, empowering partnership and loyalty management.
                </p>
              </div>
              <div class="grid grid-cols-2 md:grid-cols-4 lg:gap-[18px] gap-2">
                <button class="aspect-square">
                  <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_trader vd_active">
                    <img alt="icon1" class=" h-8" src="/dashboardVector.jpg" />
                    <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                      Vendor Management
                      <br />
                      Dashboard
                    </p>
                  </div>
                </button>
                <button class="aspect-square">
                  <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_trader">
                    <img alt="icon1" class="w-8 h-8" src="/collaborationVector.jpg" />
                    <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                      Brand Collaboration
                      <br />
                      Tools
                    </p>
                  </div>
                </button>
                <button class="aspect-square">
                  <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_trader">
                    <img alt="icon1" class="w-8 h-8" src="/customizationVector.jpg" />
                    <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                      Customizable
                      <br />
                      Campaigns
                    </p>
                  </div>
                </button>
                <button class="aspect-square">
                  <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_trader">
                    <img alt="icon1" class="w-8 h-8" src="/loyaltyprogramVector.jpg" />
                    <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                      Loyalty Program
                      <br />
                      Management
                    </p>
                  </div>
                </button>
                <button class="aspect-square">
                  <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_trader">
                    <img alt="icon1" class="w-8 h-8" src="/analyticsVector.jpg" />
                    <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                      Analytics &
                      <br />
                      Insights
                    </p>
                  </div>
                </button>
                <button class="aspect-square">
                  <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_trader">
                    <img alt="icon1" class="w-8 h-8" src="/inventoryVector.jpg" />
                    <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                      Inventory
                      <br />
                      Syncing
                    </p>
                  </div>
                </button>
                <button class="aspect-square">
                  <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_trader">
                    <img alt="icon1" class="w-8 h-8" src="/performanceTrackingVector.jpg" />
                    <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                      Performance
                      <br />
                      Tracking
                    </p>
                  </div>
                </button>
                <button class="aspect-square">
                  <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_trader">
                    <img alt="icon1" class="w-8 h-8" src="/pdfVector.jpg" />
                    <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                      PDF
                      <br />
                      Generation
                    </p>
                  </div>
                </button>
              </div>
              <div class="pt-14 flex justify-center lg:justify-start">
                <Link to="https://inficonnect.fixall.ai/">
                  <button class="lg:px-10 px-7 btn btn-anim butnshadow lg:py-3 py-2 bg-[#ea580d] text-[#fff] flex lg:text-base text-sm rounded-[6px] btn1 items-center gap-2 font-CircularMedium mainbtn">
                    Explore Product
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right-long" class="svg-inline--fa fa-arrow-right-long text-white relative left-[3px] w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path></svg>
                  </button>
                </Link>
              </div>
            </div>
          </main>
        </div>
      </section>
    </div>
  )
}

export default Inficonnect