import React, { useEffect } from 'react'
import Layout from '../components/layout'
import { MdOutlineChevronRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import WOW from 'wow.js';

function Culture() {

  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  return (
    <Layout>
        {/* <img src='/employees.png' className='w-[100%]'/> */}
        {/* <div>Culture</div> */}
        <section className="relative md:mb-[800px] lg:mb-28">
        <div className="lg:pb-96">
          <img src="/employees.png" alt="Employees" className="w-full object-cover" style={{height: "90vh", objectFit: "cover"}} />
        </div>
        <div className="md:absolute bg-[#F7F5FF] text-center md:left-1/2 md:transform md:-translate-x-1/2 w-full max-w-screen-xl xl:max-w-screen-2xl p-8 lg:-bottom-24">
          <h1 className="font-semibold text-2xl md:3xl lg:text-4xl wow fadeInDown" data-wow-animation="2s">The core values and principles</h1><h1 className='font-semibold text-2xl md:3xl lg:text-4xl wow fadeInDown' data-wow-animation="2s">That drive us</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 text-center mt-10">
            <div className="bg-white flex flex-col gap-2 md:gap-4 justify-center align-middle items-center p-5 md:p-10">
              <div className="h-14 w-14 md:h-24 md:w-24">
                <img src="/vision.png" className="h-[100%] w-[100%] object-cover wow zoomIn" data-wow-duration="1s"></img>
              </div>
              <h3 className="text-lg md:text-xl font-semibold wow zoomIn" data-wow-duration="2s">Vision</h3>
              <p className="text-sm md:text-base wow zoomInUp" data-wow-durtion="2s">25+ years of running a profitable organization gives us a good sense of challenges that a growing business faces. We take pride in running a sustainable business that’s powered by you, our customer.</p>
            </div>
            <div className="bg-white flex flex-col gap-2 md:gap-4 justify-center align-middle items-center p-5 md:p-10">
              <div className="h-14 w-14 md:h-24 md:w-24">
                <img src="/mission.png" className="h-[100%] w-[100%] object-cover wow zoomIn" data-wow-duration="1s"></img>
              </div>
              <h3 className="text-lg md:text-xl font-semibold wow zoomIn" data-wow-duration="2s">Mission</h3>
              <p className="text-sm md:text-base wow zoomInUp" data-wow-durtion="2s">Software is our craft and we back it up with our relentless investments in R&D. So much so that we prefer to own the entire technology stack, including running our data centers globally.</p>
            </div>
            <div className="bg-white flex flex-col gap-2 md:gap-4 justify-center align-middle items-center p-5 md:p-10">
              <div className="h-14 w-14 md:h-24 md:w-24">
                <img src="/coreValues.png" className="h-[100%] w-[100%] object-cover wow zoomIn" data-wow-duration="1s"></img>
              </div>
              <h3 className="text-lg md:text-xl font-semibold wow zoomIn" data-wow-duration="2s">Core Values</h3>
              <p className="text-sm md:text-base wow zoomInUp" data-wow-durtion="2s">In all these years, it's our customers' trust and goodwill that has helped us establish a strong position in the market. No matter the size of your business, we're here to help you grow.</p>
            </div>
          </div>
          {/* <div className="flex items-center justify-center mt-8">
            <Link to="/ourstory">
              <button className="bg-[#7453E0] text-white font-semibold md:text-base text-sm px-4 py-2 rounded-sm flex items-center justify-center gap-2">
                Read Our Story
                <MdOutlineChevronRight />
              </button>
            </Link>
          </div> */}

        </div>
      </section>
    </Layout>

  )
}

export default Culture