import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../pages/home'
import NotFound from '../pages/notFound'
import Aboutus from '../pages/aboutus'
import Contact from '../pages/contact'
import Investors from '../pages/investors'
import Culture from '../pages/culture'
import Inverstor from '../screens/zeroclick'

const RoutesPage = () => {
  return (
  <BrowserRouter>
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/aboutus' element={<Aboutus/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/culture' element={<Culture/>}/>
        <Route path='/investors' element={<Investors/>}/>
        <Route path='*' element={<NotFound/>}/>
    </Routes>
  </BrowserRouter>
  )
}

export default RoutesPage