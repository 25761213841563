import React from 'react'
import Layout from '../components/layout'
import ComingSoon from './comingSoon.js'

function Investors() {
    return (
        <Layout>
            {/* <div>Investors</div> */}
            <ComingSoon/>
        </Layout>
    )
}

export default Investors