import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wow.js';
import XpressbaazaarPhone from '../Images/xpressBaazaarPhone.png'

const Qwikco = () => {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  return (
    <div>
      <section class="bg-no-repeat bg-bottom bg-cover w-full md:bg-[url('https://stock-logos.dhan.co/static-openweb/Footer_Dhan_BG.svg')] bg-[url('https://stock-logos.dhan.co/static-openweb/last-banners.png')] mt-0">
        <section class="max-w-screen mx-auto md:px-10 lg:px-24 px-5">
          <main class="grid grid-cols-1 md:grid-cols-3 max-w-[1550px] mx-auto">
            <div class="md:col-span-2 py-10 md:py-7 lg:py-20 flex md:block flex-col justify-center wow zoomInUp items-start" data-wow-duration="2s">
              <img alt="img1" class="h-14 w-90 mb-5" src="/logo.png" />
              <div class="text-[#aaa] text-sm md:text-base font-CircularBook mb-8 lg:mb-8">
                We provide efficient <span class="text-orange-500"> logistics solutions </span> and
                <br />
                offer the best experience in parcel tracking
                and distribution.
              </div>
              <div class="">
                <Link to="https://www.qwikco.com/">
                  <button class="max-w-fit lg:px-10 px-7 btn btn-anim butnshadow lg:py-3 py-2 bg-orange-600 text-[#fff] rounded-[8px] btn1 flex gap-1 items-center lg:text-base text-sm items-center font-CircularBook">
                    Start Now
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right-long" class="svg-inline--fa fa-arrow-right-long text-[#fff] relative left-[3px] w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path></svg>
                  </button>
                </Link>
              </div>
            </div>
            <div class="hidden md:flex justify-center lg:justify-end items-end relative wow zoomInUp" data-wow-duration="2s">
              <img alt="xpressbaazaarPhone" class="md:h-52 lg:h-96 md:absolute md:bottom-0" src={XpressbaazaarPhone} />
            </div>
          </main>
        </section>
      </section>
    </div>
  )
}

export default Qwikco