import React from 'react'
import { Link } from 'react-router-dom'
import dashboardlaptop from '../Images/dashboardLaptop.png';

const Zeroclick = () => {
  return (
    <div> <section class="max-w-[1550px] m-auto lg:pb-10 lg:pt-20 pb-8 pt-10 lg:px-32 px-5">
      <div class="flex md:flex pb-5 justify-center">
        <div class="flex gap-2.5 items-center">
          {/* <img alt="lightning" class="w-8 hidden md:block text-orange-600" src="https://stock-logos.dhan.co/static-openweb/light2.png" /> */}
          <h2 class="text-[#363636] lg:text-3xl text-xl md:text-2xl font-CircularMedium text-center">
            Building Features&nbsp;
            <span class="text-orange-600">
              Clients Love The Most
            </span>
          </h2>
        </div>
        <br />
      </div>
      <div class="">
        <main class="grid lg:grid-cols-2 grid-cols-1 gap-5 p-5">
          <div class="">
            <div class="block tabcontent2 mb-5">
              <div class="flex items-center pt-4 gap-3 ">
                <div class="flex justify-center lg:justify-start w-full">
                  <img alt="img1" class="h-10 md:h-16" src="/zeroclickLogo.svg" />
                </div>
              </div>
              <p class="text-[#817E7E] text-center lg:text-left lg:text-base text-sm pt-5 font-CircularBook">
                It is a leading e-commerce platform that empowers businesses to create, customize, and manage their online stores with ease. It offers tools for selling products, managing inventory, and optimizing sales channels.              </p>
            </div>
          </div>
          <div class="row-span-2">
            <div class="block tab_img2 flex justify-center lg:justify-end items-center h-full">
              <img alt="img1" class="w-[90%] md:w-[50%] lg:w-[90%]" src={dashboardlaptop} />
            </div>
          </div>
          <div class="">
            <div class="grid grid-cols-2 md:grid-cols-4 lg:gap-[18px] gap-2">
              <button class="aspect-square">
                <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_invests vd_active">
                  <img alt="icon1" class="h-8" src="/customizableVector.jpg" />
                  <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                    Customizable
                    <br />
                    Storefront
                  </p>
                </div>
              </button>
              <button class="aspect-square">
                <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_invests">
                  <img alt="icon1" class="h-8" src="/multichannelVector.jpg" />
                  <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                    Multi-Channel
                    <br />
                    Selling
                  </p>
                </div>
              </button>
              <button class="aspect-square">
                <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_invests">
                  <img alt="icon1" class="h-8" src="/securepaymentVector.jpg" />
                  <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                    Secure Payment
                    <br />
                    Processing
                  </p>
                </div>
              </button>
              <button class="aspect-square">
                <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_invests">
                  <img alt="icon1" class="w-8 h-8" src="/inventoryVector.jpg" />
                  <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                    Inventory
                    <br />
                    Management
                  </p>
                </div>
              </button>
              <button class="aspect-square">
                <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_invests">
                  <img alt="icon1" class="h-8" src="/marketingtoolVector.jpg" />
                  <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                    Marketing
                    <br />
                    Tools
                  </p>
                </div>
              </button>
              <button class="aspect-square">
                <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_invests">
                  <img alt="icon1" class="h-8" src="/appintegrationVector.png" />
                  <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                    App
                    <br />
                    Integrations
                  </p>
                </div>
              </button>
              <button class="aspect-square">
                <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_invests">
                  <img alt="icon1" class="h-8" src="/mobileoptimizationVector.png" />
                  <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                    Mobile
                    <br />
                    Optimized
                  </p>
                </div>
              </button>
              <button class="aspect-square">
                <div class="rounded-[16px] !h-full w-full justify-center flex flex-col bg-white items-center border cursor-pointer border-[#EBEBEB] hover:border-[#f9d49d] hover:drop-shadow-[0px_2px_10px_rgba(239,147,9,0.20)] vd_invests">
                  <img alt="icon1" class="h-8" src="/customersupportVector.jpg" />
                  <p class="text-[#817E7E] pt-2 lg:text-sm text-xs text-center font-CircularBook">
                    24/7 Customer
                    <br />
                    Support
                  </p>
                </div>
              </button>
            </div>
            <div class="pt-14 flex justify-center lg:justify-start">
              <div class="">
                <Link to="https://fixlabs-zeroclick.vercel.app/">
                  <button class="lg:px-10 px-7 btn btn-anim butnshadow lg:py-3 py-2 bg-orange-600 text-[#fff] flex lg:text-base text-sm rounded-[6px] btn1 items-center gap-2 font-CircularMedium mainbtn">
                    Explore Product
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right-long" class="svg-inline--fa fa-arrow-right-long text-white relative left-[3px] w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path></svg>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </section></div>
  )
}

export default Zeroclick