import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import WOW from "wow.js";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    new WOW().init();
  }, []);

  const validateField = (name, value) => {
    let error = "";
  
    if (!value.trim()) {
      error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`;
    } else if (name === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      error = "Invalid email address.";
    } else if (name === "name" && !/^[a-zA-Z\s]+$/.test(value)) {
      error = "Name must contain only alphabetic characters.";
    }
  
    return error;
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Validate individual field on change
    setFormErrors({
      ...formErrors,
      [name]: validateField(name, value),
    });
  };

  const validateForm = () => {
    const errors = {};

    for (const [name, value] of Object.entries(formData)) {
      const error = validateField(name, value);
      if (error) {
        errors[name] = error;
      }
    }

    setFormErrors(errors);

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Form submitted successfully", formData);
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      })
      // Add your form submission logic here
    } else {
      console.log("Form contains errors", formErrors);
    }
  };

  return (
    <Layout>
      <div
        className="md:min-h-screen flex items-center justify-end bg-cover bg-center bg-no-repeat md:p-8"
        style={{ backgroundImage: `url('/contactusBackground.png')` }}
      >
        <div
          className="bg-gradient-to-br from-[#f0f9ff] to-[#cfe8ff] w-full max-w-4xl p-4 shadow-lg md:p-8 rounded-lg wow fadeInRight"
          data-wow-delay="0.2s"
        >
          <h2
            className="text-4xl text-[#0078d4] font-bold text-center mb-6 wow fadeInDown"
            data-wow-delay="0.3s"
          >
            Contact Us
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Name */}
            <div className="wow fadeInUp" data-wow-delay="0.4s">
              <label className="block text-gray-800 font-medium mb-2">
                Name:
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className={`w-full px-4 py-3 border ${
                  formErrors.name ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 ${
                  formErrors.name ? "focus:ring-red-500" : "focus:ring-[#0078d4]"
                }`}
                placeholder="Enter your name"
                required
              />
              {formErrors.name && (
                <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
              )}
            </div>

            {/* Email */}
            <div className="wow fadeInUp" data-wow-delay="0.5s">
              <label className="block text-gray-800 font-medium mb-2">
                Email:
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className={`w-full px-4 py-3 border ${
                  formErrors.email ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 ${
                  formErrors.email
                    ? "focus:ring-red-500"
                    : "focus:ring-[#0078d4]"
                }`}
                placeholder="Enter your email"
                required
              />
              {formErrors.email && (
                <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
              )}
            </div>

            {/* Subject */}
            <div className="wow fadeInUp" data-wow-delay="0.6s">
              <label className="block text-gray-800 font-medium mb-2">
                Subject:
              </label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                className={`w-full px-4 py-3 border ${
                  formErrors.subject ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 ${
                  formErrors.subject
                    ? "focus:ring-red-500"
                    : "focus:ring-[#0078d4]"
                }`}
                placeholder="Subject"
                required
              />
              {formErrors.subject && (
                <p className="text-red-500 text-sm mt-1">{formErrors.subject}</p>
              )}
            </div>

            {/* Message */}
            <div className="wow fadeInUp" data-wow-delay="0.7s">
              <label className="block text-gray-800 font-medium mb-2">
                Message:
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className={`w-full px-4 py-3 border ${
                  formErrors.message ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 ${
                  formErrors.message
                    ? "focus:ring-red-500"
                    : "focus:ring-[#0078d4]"
                }`}
                placeholder="Your message"
                rows="5"
                required
              ></textarea>
              {formErrors.message && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.message}
                </p>
              )}
            </div>

            {/* Submit Button */}
            <div className="text-center wow fadeInUp" data-wow-delay="0.5s">
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-[#0078d4] to-[#00a6ff] text-white py-3 rounded-lg shadow-lg hover:from-[#00a6ff] hover:to-[#0078d4] transition duration-300"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
