import React, { useEffect } from "react";
import WOW from 'wow.js';

const Solutions = () => {

  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  return (
    <div>
      <div class="m-auto px-5 md:px-10 xl:px-24 lg:pt-10 lg:pb-14 pt-10 pb-10 lg:z-10 lg:relative">
        <h2 class="text-black lg:text-3xl text-xl md:text-2xl font-CircularMedium text-center py-1 pb-5 wow fadeInDown" data-wow-animation="2s">
          Our Unified
          <span class="text-[#ea580d]"> Solutions</span>
        </h2>
        <div class="">
          <div class="grid lg:grid-cols-8 md:grid-cols-4 grid-cols-2 md:gap-6 gap-4 lg:pt-5">
            <div class="flex items-end wow slideInLeft" data-wow-duration="1s">
              <a href="https://fixall.ai" class="w-full">
                <div class="rounded-lg aspect-square w-full pt-4 pb-4 justify-center flex flex-col bg-white items-center border-slate-300 border cursor-pointer  hover:border-orange-500 hover:drop-shadow-[0px_2px_10px_rgba(249,115,22,0.5)] p-2">
                  <img
                    src="/analytic.png"
                    class="lg:w-5/12 w-4/12 h-auto"
                    alt="icon1"
                  />
                  <p class="text-black pt-2 text-center lg:text-sm md:text-sm text-xs font-CircularMedium">
                    Sales Analytics
                  </p>
                </div>
              </a>
            </div>
            <div class="flex items-end wow slideInLeft" data-wow-duration="1s">
              <a href="https://fixall.ai" class="w-full">
                <div class="rounded-lg aspect-square w-full pt-4 pb-4 justify-center flex flex-col bg-white items-center border-slate-300 border cursor-pointer  hover:border-orange-500 hover:drop-shadow-[0px_2px_10px_rgba(249,115,22,0.5)] p-2">
                  <img
                    src="/pos.png"
                    class="lg:w-5/12 w-4/12 h-auto"
                    alt="icon1"
                  />
                  <p class="text-black pt-2 text-center lg:text-sm md:text-sm text-xs font-CircularMedium">
                    POS
                  </p>
                </div>
              </a>
            </div>
            <div class="flex items-end wow slideInLeft" data-wow-duration="1s">
              <a href="https://fixall.ai" class="w-full">
                <div class="rounded-lg aspect-square w-full pt-4 pb-4 justify-center flex flex-col bg-white items-center border-slate-300 border cursor-pointer  hover:border-orange-500 hover:drop-shadow-[0px_2px_10px_rgba(249,115,22,0.5)] p-2">
                  <img
                    src="/ai.png"
                    class="lg:w-5/12 w-4/12 h-auto"
                    alt="icon1"
                  />
                  <p class="text-black pt-2 text-center lg:text-sm md:text-sm text-xs font-CircularMedium">
                    AI Automation
                  </p>
                </div>
              </a>
            </div>
            <div class="flex items-end wow slideInLeft" data-wow-duration="1s">
              <a href="https://fixall.ai" class="w-full">
                <div class="rounded-lg aspect-square w-full pt-4 pb-4 justify-center flex flex-col bg-white items-center border-slate-300 border cursor-pointer  hover:border-orange-500 hover:drop-shadow-[0px_2px_10px_rgba(249,115,22,0.5)] p-2">
                  <img
                    src="/logistic.png"
                    class="lg:w-5/12 w-4/12 h-auto"
                    alt="icon1"
                  />
                  <p class="text-black pt-2 text-center lg:text-sm md:text-sm text-xs font-CircularMedium">
                    Logistic
                  </p>
                </div>
              </a>
            </div>
            <div class="flex items-end wow slideInRight" data-wow-duration="1s">
              <a href="https://fixall.ai" class="w-full">
                <div class="rounded-lg aspect-square w-full pt-4 pb-4 justify-center flex flex-col bg-white items-center border-slate-300 border cursor-pointer  hover:border-orange-500 hover:drop-shadow-[0px_2px_10px_rgba(249,115,22,0.5)] p-2">
                  <img
                    src="/ondc.png"
                    class="lg:w-5/12 w-4/12 h-auto"
                    alt="icon1"
                  />
                  <p class="text-black pt-2 text-center lg:text-sm md:text-sm text-xs font-CircularMedium">
                    ONDC
                  </p>
                </div>
              </a>
            </div>
            <div class="flex items-end wow slideInRight" data-wow-duration="1s">
              <a href="https://fixall.ai" class="w-full">
                <div class="rounded-lg aspect-square w-full pt-4 pb-4 justify-center flex flex-col bg-white items-center border-slate-300 border cursor-pointer  hover:border-orange-500 hover:drop-shadow-[0px_2px_10px_rgba(249,115,22,0.5)] p-2">
                  <img
                    src="/supply-chain.png"
                    class="lg:w-5/12 w-4/12 h-auto"
                    alt="icon1"
                  />
                  <p class="text-black pt-2 text-center lg:text-sm md:text-sm text-xs font-CircularMedium">
                    Supply Chain
                  </p>
                </div>
              </a>
            </div>
            <div class="flex items-end wow slideInRight" data-wow-duration="1s">
              <a href="https://fixall.ai" class="w-full">
                <div class="rounded-lg aspect-square w-full pt-4 pb-4 justify-center flex flex-col bg-white items-center border-slate-300 border cursor-pointer  hover:border-orange-500 hover:drop-shadow-[0px_2px_10px_rgba(249,115,22,0.5)] p-2">
                  <img
                    src="/apis.png"
                    class="lg:w-5/12 w-4/12 h-auto"
                    alt="icon1"
                  />
                  <p class="text-black pt-2 text-center lg:text-sm md:text-sm text-xs font-CircularMedium">
                    Custom API
                  </p>
                </div>
              </a>
            </div>
            <div class="flex items-end wow slideInRight" data-wow-duration="1s">
              <a href="https://fixall.ai" class="w-full">
                <div class="rounded-lg aspect-square w-full pt-4 pb-4 justify-center flex flex-col bg-white items-center border-slate-300 border cursor-pointer  hover:border-orange-500 hover:drop-shadow-[0px_2px_10px_rgba(249,115,22,0.5)] p-2">
                  <img
                    src="/d2c.png"
                    class="lg:w-5/12 w-4/12 h-auto"
                    alt="icon1"
                  />
                  <p class="text-black pt-2 text-center lg:text-sm md:text-sm text-xs font-CircularMedium">
                    D2C
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-center lg:pt-10 pt-5">
          <p class="text-[#817E7E] lg:text-base text-center md:text-base text-xs font-CircularRegular">
          Our unified SaaS platform integrates inventory, sales, marketing, and logistics, empowering retailers to scale faster, personalize experiences,<br/> and boost revenue while reducing operational complexity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
